import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visionImg from "../../Assets/about_p2_img@2x.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>Team Introduction</h1>
            <p>We are a team that is passionate about technology and life.<br/> Our core team members are primarily based in Shenzhen, Hong Kong, and the United States. </p>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
          <Col md={4} className="about-img">
            <img src={visionImg} className="img-fluid" />
          </Col>
          <Col md={4}>
            <p style={{ textAlign: 'left', fontSize: "1.1rem", color: "#547079" }}>
            <strong style={{color: '#222'}}>Most of our members have over ten years of development experience.</strong><br/><br/>
            Among them, some have created internet products with over a hundred million users at Baidu. <br/><br/>
            Others have worked for several years at the security company McAfee in the United States, gaining profound knowledge in security and having early exposure to BTC. <br/><br/>
            We also have members who come from the founding team of a publicly traded internet finance company in the United States, with experience in developing core banking systems and apps.<br/><br/>
            Now, we have come together because of our enthusiasm for web3 and have devoted ourselves wholeheartedly to the web3 industry. <br/><br/>We are eager to contribute our efforts to the development of web3.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
