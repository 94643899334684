import React, { useState } from "react";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import {
  AiFillAndroid,
} from 'react-icons/ai'
import { FaGooglePlay,FaApple } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import spaceImg from "../../Assets/mtv-space.jpg";
import msgImg from "../../Assets/mtv-message.jpg";
import meImg from "../../Assets/mtv-me.jpg";
import createAccountImg from "../../Assets/mtv-create-account.jpg";

function Download() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid className="download-section">
      <Container>
        <Row className="align-items-center" style={{ color: '#000', justifyContent: 'center' }}>
          <Col md={4}>
            <Row>
              <Col md={12} className="title-wrap">
                <h1 style={{textAlign:'left'}}>TinyVerse Space</h1>
                <p style={{textAlign:'left'}}>Building a private safehouse for you in the digital world.</p>
              </Col>
            </Row>
            <Row style={{padding: "10px 0"}}>
              <Col md={6}>
                <a className="site-btn" onClick={handleShow}>
                  <FaApple style={{fontSize:'2.2rem'}}/>&nbsp;App Store
                </a>
              </Col>
              <Col md={6}>
                <a className="site-btn" href="https://play.google.com/store/apps/details?id=com.tinyverse.tvs" target='_blank'>
                <FaGooglePlay style={{fontSize:'2.2rem'}}/>&nbsp;Google Play
                </a>
              </Col>

              <Col md={6}>
                <a className="site-btn" href='https://github.com/tinyverse-web3/mtv-app/releases/download/Release1.1.0/tinyverse-space-1.1.0-20230928175927.apk' target='_blank'>
                <AiFillAndroid style={{fontSize:'2.2rem'}}/>&nbsp;Android APK
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Swiper pagination={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }} modules={[Pagination, Autoplay]}>
              <SwiperSlide>
                <img src={createAccountImg} style={{ width: "50%", height: "50%", borderRadius: "0.5rem" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={spaceImg} style={{ width: "50%", height: "50%", borderRadius: "0.5rem" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={msgImg} style={{ width: "50%", height: "50%", borderRadius: "0.5rem" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={meImg} style={{ width: "50%", height: "50%", borderRadius: "0.5rem" }} />
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tips</Modal.Title>
          </Modal.Header>
          <Modal.Body>The app is coming to the apple store.</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} variant="primary">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Container>
  );
}

export default Download;
