import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import intro04Img from "../../Assets/p5_1_img@2x.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>
              Technical Foundation
            </h1>
            <p>
              Based on the concept of "controlling my own data",<br/> we have created a safe and self-controlled digital space for ourselves to save our most important data without worrying about data leakage or data loss.<br/> The core technologies in this space include:
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col
            md={8}
            style={{
              justifyContent: "center",
            }}
          >
            <Swiper pagination={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }} modules={[Pagination, Autoplay]}>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <blockquote className="blockquote mb-0">
                      <ul>
                        <li className="about-activity">
                          <h3>Non-custodial accounts based on asymmetric keys</h3>
                          <p> The account key is generated by the advanced Ed25519 algorithm, and the user data is encrypted by the AES256 algorithm to ensure the security of the account and data. The private key of the account is managed by the user himself and only exists in the user's own mobile phone memory. </p>
                          <p> The biggest challenge faced by users is how to manage their private keys safely and conveniently. This is our core work. Here are the results of our efforts:</p>
                        </li>

                      </ul>
                    </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro04Img} className="img-fluid" />
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <blockquote className="blockquote mb-0">
                      <ul>
                        <li className="about-activity">
                          <h3>Multi-method recovery account</h3>
                          <p>We provide a variety of ways to help users back up and restore their accounts, eliminating the risk of lost and stolen mnemonic phrases or the shutdown of a centralized service.
                            <br /><strong className="purple">Guardian recovery</strong>: Retrieve key shard data by verifying the guardian account.
                            <br /><strong className="purple">Intelligent Privacy Recovery</strong>: Generate private key shards by customizing questions and answers involving personal privacy.
                            <br /><strong className="purple">Encrypted mnemonic recovery</strong>: Use the vault to encrypt the mnemonic, and only you can unlock the vault.
                            <br /><strong className="purple">Smart inheritance recovery</strong>: Use smart contracts to automatically transfer control to heirs when the account has not been used for too long.
                          </p>
                        </li>

                      </ul>
                    </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro04Img} className="img-fluid" />
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <blockquote className="blockquote mb-0">
                      <ul>
                        <li className="about-activity">
                          <h3>Multi-factor authentication</h3>
                          <p> Users need to verify the vault and guardian accounts separately to obtain and decrypt shard data. These are two independent verification mechanisms that make full use of multiple levels of user privacy data and do not require additional memory from the user.
                            <br /><strong className="purple">Vault</strong>: Users can generate a vault by providing passwords, private data, fingerprints, etc. as template data to protect the user's private key shard data.
                            <br /><strong className="purple">Guardian</strong>: Users rely on their own other accounts that can be authenticated to save private key shard data to the IPFS network. </p>
                        </li>
                      </ul>
                    </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro04Img} className="img-fluid" />
                  </Col>
                </Row>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
