import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/banner_img@2x.png";
import Intro1 from "../Intro/Intro-1";
import Intro2 from "../Intro/Intro-2";
import Intro3 from "../Intro/Intro-3";
import Intro4 from "../Intro/Intro-4";
// import Partners from "./Partners";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={6}>

              <h1 className="heading-name">
                <strong> TinyVerse Space</strong>
              </h1>

              <p className="heading">
                <br />A personal safehouse in the digital world
                <br />
                <br />Store and manage your most important data, and explore ways to monetize data and increase asset value.
                <br />Based on IPFS, open source, and decentralized, you have complete control over your account and data and cannot be accessed by any third party.
                <br />
              </p>

              <p className="heading italic">
                "The storm may enter; the rain may enter; but the King of England may not enter."{" "}
              </p>

              <div style={{ padding: 50, textAlign: "left" }}>
                {/* <Type /> */}
              </div>
            </Col>

            <Col md={6}>
              <img src={homeLogo} className="img-fluid" style={{ maxHeight: "700px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Intro1 />
      <Intro2 />
      <Intro3 />
      <Intro4 />
      {/* <Projects /> */}
      {/* <Partners/> */}
    </section>
  );
}

export default Home;
