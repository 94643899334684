import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import intro01Img from "../../Assets/explore.png";

function Intro() {
  return (
    <>
      <Container fluid>

        <Container>
          <Row style={{ justifyContent: "center", padding: "10px 0" }}>
            <Col md={9} className="title-wrap">
              <h1>
                Let money make money
              </h1>
              <p>
                Under the premise of security, it is what everyone expects to have their own tokens bring more tokens. (In development)
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <div className="intro-section-3">
        <div className="intro-section-3-bg">
          <Swiper
            className="slider"
            slidesPerView={'auto'}
            spaceBetween={30}>
            <SwiperSlide className="slide">
              <Card className="quote-card-view">
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <ul>
                      <li className="about-activity">
                        <h3>Secret quantitative trading strategies</h3>
                        <p> Let your own AI assistant execute your own trading strategy. This is the safest quantitative trading platform, because it all runs on your mobile phone, and no third party understands your trading strategy and can formulate trading strategies against your opponents.</p>
                      </li>
                    </ul>
                  </blockquote>
                </Card.Body>
              </Card>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <Card className="quote-card-view">
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <ul>
                      <li className="about-activity">
                        <h3>Decentralized staking</h3>
                        <p> Even if you keep an old mobile phone connected to the Internet at home, it may become part of the mortgage node, ensuring the safety of your funds while earning risk-free interest.
                        </p>
                      </li>
                    </ul>
                  </blockquote>
                </Card.Body>
              </Card>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <Card className="quote-card-view">
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <ul>
                      <li className="about-activity">
                        <h3>More</h3>
                        <p> We continue to explore safe ways of making money because it is also our own expectation.
                        </p>
                      </li>
                    </ul>
                  </blockquote>
                </Card.Body>
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Intro;
