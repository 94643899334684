import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";
import intro2Img1 from "../../Assets/p3_1_img@2x.png";
import intro2Img2 from "../../Assets/p3_2_img@2x.png";
import intro2Img3 from "../../Assets/p3_3_img@2x.png";

function Intro() {
  return (
    <Container fluid className="intro-section-2">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>Exploit value</h1>
            <p>
              Different types of data have different values. Some are equivalent to assets in themselves (such as Tokens in wallets), and some have yet to be exploited. We exploit the value of data in these two aspects:
              <br />
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Row style={{ justifyContent: "center", padding: "12px 0" }}>
              <Col md={2} className="about-img">
                <img src={intro2Img1} className="img-fluid" />
              </Col>
              <Col md={10}>
                <Card className="quote-card-view">
                  <Card.Body>
                    <blockquote className="blockquote mb-0">
                      <div className="about-activity">
                        <h3>Creator economy: monetization of works</h3>
                        <p>Everyone has a creative talent. Individuals can independently publish their works into NFT with encrypted content.</p>
                        <p>Combined with smart contracts, there is no need to sell NFT, but to earn income by paying to view the content. It can also stimulate the widespread dissemination of works through forwarding sharing contracts, so that the value of personal works can be fully reflected.</p>
                      </div>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", padding: "12px 0" }}>
              <Col md={2} className="about-img">
                <img src={intro2Img2} className="img-fluid" />
              </Col>
              <Col md={10}>
                <Card className="quote-card-view">
                  <Card.Body>
                    <blockquote className="blockquote mb-0">
                      <div className="about-activity">
                        <h3>Personal intelligent assistant</h3>
                        <p>A general intelligent assistant cannot bring unique value to an individual. Only by building a unique personal intelligent assistant based on personal data can it bring unique value to an individual and maintain an advantage in the competition.</p>
                        <p>The more personal data there is, the more AI can leverage the individual`s unique strengths and abilities. Personal privacy, accumulated knowledge and experience, and personal works can all be used to train personal intelligent assistants.</p>
                      </div>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", padding: "12px 0" }}>
              <Col md={2} className="about-img">
                <img src={intro2Img3} className="img-fluid" />
              </Col>
              <Col md={10}>
                <Card className="quote-card-view">
                  <Card.Body>
                    <blockquote className="blockquote mb-0">
                      <div className="about-activity">
                        <h3>Explore more possibilities</h3>
                        <p>This is something we continue to work hard on. If you have any great ideas, please contact us directly.</p>
                      </div>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
