import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visionImg from "../../Assets/about_banner_img@2x.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>
              Our Vision
            </h1>
            <p>
              Helping users build a private safehouse in the digital world,<br /> experiencing and creating a better digital life.
            </p>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
          <Col md={4}>
            <p style={{ textAlign: 'left', fontSize: "1.1rem", color: "#547079" }}>
              Our goal is to help users build a private safehouse in the digital world, giving them full control over their personal data.
              <br /><br />By integrating AI technology, users can infinitely expand their capabilities and create a better digital life.
            </p>
          </Col>
          <Col md={5} className="about-img">
            <img src={visionImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
