import React from "react";
// import teamImg from "../../Assets/home-bg.jpg";
import About1 from "./About-1";
import About2 from "./About-2";
import About3 from "./About-3";

function About() {
  return (
    <section>
      <About1/>
      <About2/>
      <About3/>
    </section>
  );
}

export default About;
