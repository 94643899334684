import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillMediumCircle,
  AiOutlineTwitter,
  AiFillGithub,
  AiOutlineMail,
} from 'react-icons/ai'
import visionImg from "../../Assets/about_p3_img@2x.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>

      <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>Contact Us</h1>
            <p>If you are interested in our products or would like to join us,<br/> please feel free to email us directly or reach out to us through our official social media channels.</p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
          <Col md={4} style={{textAlign: "left"}}>
            <div className="site-btn"><AiOutlineMail/>&nbsp; support@tinyverse.space</div>
            <div className="site-btn"><AiOutlineTwitter/>&nbsp; https://twitter.com/tinyverse_space </div>
            <div className="site-btn"><AiFillGithub/>&nbsp; https://github.com/tinyverse-web3 </div>
            <div className="site-btn"><AiFillMediumCircle/>&nbsp; https://medium.com/@tinyverse_space </div>
          </Col>
          <Col md={5} className="about-img">
            <img src={visionImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
