import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import intro03Img from "../../Assets/p2_3_img@2x.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={9} className="title-wrap">
            <h1>
              Store and manage your most important data
            </h1>
            <p>
              Private data (diaries, passwords, bank cards, address books, etc.), accumulated knowledge and experience, life experiences, creative works, digital assets, etc., your most important data needs to be permanently stored in a place that can ensure privacy, data security, and ease of use.
              <br />
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={8}>
            <Swiper pagination={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }} modules={[Pagination, Autoplay]}>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                        <blockquote className="blockquote mb-0">
                          <ul>
                            <li className="about-activity">
                              <h3>Transparent data encryption and decryption</h3>
                              <p> Data stored in the user's digital space is automatically encrypted and decrypted by the AES256 algorithm. No one can decrypt the data except the user himself, ensuring data privacy.</p>
                            </li>

                          </ul>
                        </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro03Img} className="img-fluid" />
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                        <blockquote className="blockquote mb-0">
                          <ul>
                            <li className="about-activity">
                              <h3>Decentralized storage to the IPFS network</h3>
                              <p> Encrypted data is stored in a decentralized manner on the IPFS network, ensuring that no third party can access and control user data, ensuring data security.</p>
                              <p> </p>
                            </li>

                          </ul>
                        </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro03Img} className="img-fluid" />
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row style={{ justifyContent: "center", padding: "10px 0" }}>
                  <Col
                    md={8}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                        <blockquote className="blockquote mb-0">
                          <ul>
                            <li className="about-activity">
                              <h3>Open source ensures trustworthiness</h3>
                              <p> The app is open source, ensuring that everything operates as stated, and anyone can inspect the code to ensure reliability and security, and to find and fix potential security holes.
                              </p>
                            </li>

                          </ul>
                        </blockquote>
                  </Col>
                  <Col md={4} className="about-img">
                    <img src={intro03Img} className="img-fluid" />
                  </Col>
                </Row></SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
